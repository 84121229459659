const config = {
  appId: 'd31f44fd-11f3-43ba-a378-7b09df3bf900',
  aryaApiUrl: 'https://staging.goarya.com/aryaapi/api',
  apiV1Url: 'https://app-staging.goarya.com/api/v1',
  apiV2Url: 'https://app-staging.goarya.com/api/v2',
  apiV3Url: 'https://app-staging.goarya.com/api/v3',
  apiV4Url: 'https://app-staging.goarya.com/api/v3.1',
  apiV5Url: 'https://app-staging.goarya.com/api/v3.2',
  defaultSelectedPageSize: 10,
  defaultPageSizes: [10, 20],
  urls: {
    aryaPay: 'https://pay-staging.goarya.com/api/v1',
    aryaConnect: 'https://connectapi-staging.goarya.com/api/v1',
    aryaConnectV2Client: 'https://connect-staging.goarya.com/v2',
    aryaCalendar: 'https://calendar-staging.goarya.com',
    aryaConnectCandidatePage: 'https://connect-staging.goarya.com/chat',
    aryaConnectNotifications: 'https://connectapi-staging.goarya.com/hubs/notification',
    aryaConnectChat: 'https://connectapi-staging.goarya.com/hubs/connect',
    aryaNotifySocketEndpoint: 'https://notify-staging.goarya.com',
    aryaNotify: 'https://notify-staging.goarya.com/api/v1',
    aryaConnectChatV2: 'https://connectapi-staging.goarya.com/hubs/v2/connect',
    aryaConnectApiV2Client: 'https://connectapi-staging.goarya.com/api/v2',
    pulseHostName: 'pulse-staging.goarya.com',
    getWhiteLabelResourceUrl: client => `https://arya3-whitelabel.s3.amazonaws.com/${client.toLowerCase()}-review`,
    smartjob: 'https://jobsapi.goarya.com/api/v1',
    atlasApiUrl: 'https://atlas-staging.goarya.com/api/v1',
    pulseMarketingUrl: 'https://goarya.com/arya-pulse/',
    getAppConfiguration: appType =>
      `https://arya3-whitelabel.s3.amazonaws.com/configuration-review/settings-${appType}.json`,
    botSummaryViewUrl: 'https://pulse-staging.goarya.com/jobs/{0}/candidates?status=connected',
    verifiedRedirectUri: 'https://aryapulse.page.link/get-started',
    appUrl: 'https://app-staging.goarya.com',
    env: 'staging',
    getGtagUrl: key => `https://www.googletagmanager.com/gtag/js?id=${key}`,
    careerportalUrl:
      'https://portal-staging.goarya.com/leoforcestagingnew/jobs/se/{{jobId}}/candidates/{{candidateId}}/_applicationReview?aTime={{appliedTime}}&r={{rId}}&acId={{aryaCandidateId}}',
    atsUserProfile: 'https://qa.leoforce.com/Leo/Home/UserProfile',
    knowledgeBase: 'https://knowledgebase-staging.goarya.com',
    getSubmitCandidateUrl: (jobGuid, jobName) =>
      `https://portal-staging.goarya.com/leoforce/jobs/${encodeURIComponent(jobName)}/${jobGuid}/_interestSupport`,
    aryaGpt: 'https://aryagpt-staging.goarya.com',
  },
  mycareers: {
    clientId: '6fed6028-632a-4c28-91ca-aa4eb7655cdb',
    url: 'https://portal-staging.goarya.com',
  },
  whiteLabelClients: ['PeopleScout', 'Joveo', 'Churchstreeter'],
  ipstack: {
    url: 'https://api.ipstack.com',
    key: 'ee2475a24d765710e3af3367c3187678',
  },
  auth: {
    cognito: {
      clientId: '7vtslg5jbkjt2dnec3saajv7dm',
      poolId: 'us-east-1_OmTFZPYTB',
      region: 'us-east-1',
      domain: 'aryaidentity-staging.auth.us-east-1.amazoncognito.com',
      redirectUri: 'https://app-staging.goarya.com',
      identityPoolId: 'us-east-1:cb56fabb-aaef-4c30-badc-5dd321a47031',
      roleArn: 'arn:aws:iam::700190100279:role/Cognito_arya_ui_identity_pool_staging_Auth_Role',
      revokeEndpoint:'https://auth-staging.goarya.com/oauth2/'
    },
  },
  googleAnalytics: {
    key: 'G-MGS0H52ZN8',
    container: 'GTM-M6Z54PT',
  },
  advanceSearchActivity: {
    Api: 'https://staging.goarya.com/aryaapi',
  },
  smartKarrot: {
    appId: 'cf1eabda-b946-4746-9ae4-6d703f4310b9',
    variant: 'option-c',
    productId: '2a1ef5ae-8fd5-4fa9-925b-19672a911e6f',
    isEnabled: false,
  },
  partnerstack: {
    key: 'pk_KMY7mMEvJOmd1Qu3wVgqKyfy84k3d20x',
  },
  pivotRootGTMContainer: 'GTM-MC4V3L4',
  sipml: {
    publicIdentity: 'sip:1220@voip.goarya.com',
    privateIdentity: '1220',
    realm: 'voip.goarya.com',
    uri: '1220@voip.goarya.com',
    wsServers: 'wss://voip.goarya.com:443/ws',
    password: 'Le0$)rCe@(*&%$#2018@sPeC',
    displayName: '+19198000110',
    iceServers: [
      {
        urls: 'stun:voip-ice.goarya.com:3478',
      },
      {
        urls: 'turn:voip-ice.goarya.com:3478',
        username: 'aryavoip',
        credential: 'aryavoip@123',
      },
    ],
  },
  email: {
    oauth: {
      google: {
        baseUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
        redirectUri: 'https://connectapi-staging.goarya.com/api/v1/oauth',
        clientId: '644437064378-inlvp3muk90i92ir4hubq4png1n3vls3.apps.googleusercontent.com',
      },
      office365: {
        baseUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
        redirectUri: 'https://connectapi-staging.goarya.com/api/v1/oauth/',
        clientId: '2b08fd50-f137-413d-97bc-5e5b41d79254',
      },
    },
  },
  portal: {
    oauth: {
      careerBuilderEdge: {
        baseUrl: 'https://auth.careerbuilder.com/connect/authorize?',
        redirectUri: 'https://app-staging.goarya.com/api/v3/oauth',
        clientId: 'Cdcfe834d',
      },
    },
  },
  sso: {
    providers: [
      {
        path: '/lenovo',
        identifier: 'Lenovo',
      },
      {
        path: '/samling',
        identifier: 'Samling',
      },
      {
        path: '/adprm',
        identifier: 'adprm',
      },
      {
        path: '/adpwn',
        identifier: 'adpwn',
      },
    ],
  },
  SMTP: {
    Providers: [
      {
        ProviderName: 'Manual',
        AuthScheme: 'Manual',
      },
      {
        ProviderName: 'google',
        AuthScheme: 'OAuth',
      },
    ],
  },
  ReactEmailEditor: { Bucket: 'arya-react-email-editor-staging' },
  Olark: { Id: '1271-114-10-1180' },
  Clarity: { Id: 'lutxqhl5x2' },
  trustedResumeResourceDomains: ['cv.cvlibrary.co.uk'],
  charactersPerMessage: 152,
  supportEmail: 'support@leoforce.com',
  getRequestCreditsEmailContent: ({ orgName, userName, amount, requestedCreditMessage, orgId }) => ({
    Subject: `Request to add ${requestedCreditMessage} credits from ${orgName}(${orgId}) - Admin level`,
    Body: `Requested ${requestedCreditMessage} credits from: ${orgName}(${orgId}), Requested by User Name: ${userName}, Requested Credits: ${amount}`,
  }),
  consentTemplateBody:
    'Hi {{Candidate.Name}}! Are you interested in exploring {{Job.Title}} opportunity at {{Organization.Name}}? Please reply YES if interested, NO for not interested (or) STOP if you do not want to receive texts about {{Organization.Name}} Job opportunities. Thanks, {{Recruiter.FirstName}}, Recruiter',
  consentTemplateName: 'Mandatory Consent Message',
  consentOption:
    'Please reply YES if interested, NO for not interested (or) STOP if you do not want to receive texts about {{Organization.Name}} Job opportunities.',
  privacyLink: 'https://goarya.com/data-privacy-policy/',
  termsOfUseLink: 'https://goarya.com/terms-of-use/',
  Workflow: {
    ScratchTemplate: 'https://arya-workflow.s3.amazonaws.com/systemtemplate-review/scratchtemplate.svg',
    DefaultTemplate: 'https://arya-workflow.s3.amazonaws.com/systemtemplate-review/defaulttemplate.svg',
  },
  Product: {
    ProductVariants: [
      {
        Name: 'SMS',
        Id: '2A7FFE10-24BB-11EF-AE22-0EA2F1D5502F',
      },
    ],
  },
};

export default config;
